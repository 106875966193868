import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './components/input/input.component';
import { InputWithLegendComponent } from './components/input-with-legend/input-with-legend.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from './components/select/select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    ConfirmationDeleteDialogComponent
} from './components/confirmation-delete-dialog/confirmation-delete-dialog.component';
import { VimeoPlayerComponent } from './components/vimeo-player/vimeo-player.component';
import { TableComponent } from './components/table/table.component';
import { CdkTableModule } from '@angular/cdk/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TagsWithBorderComponent } from './components/tags-with-border/tags-with-border.component';
import { BooleanStatusIconComponent } from './components/boolean-status-icon/boolean-status-icon.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { IconTitleSliderComponent } from './components/icon-title-slider/icon-title-slider.component';
import { VjsPlayerComponent } from './components/vjs-player/vjs-player.component';


@NgModule({
    declarations: [InputComponent, InputWithLegendComponent, SelectComponent, ConfirmationDeleteDialogComponent,
        VimeoPlayerComponent, TableComponent, TagsWithBorderComponent, BooleanStatusIconComponent,
        ImagePreviewComponent, DateTimePickerComponent, IconTitleSliderComponent, VjsPlayerComponent],
    exports: [
        InputComponent,
        InputWithLegendComponent,
        SelectComponent,
        VimeoPlayerComponent,
        TableComponent,
        TagsWithBorderComponent,
        BooleanStatusIconComponent,
        NgSelectModule,
        ImagePreviewComponent,
        DateTimePickerComponent,
        IconTitleSliderComponent,
        VjsPlayerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        CdkTableModule,
        NgbModule,
        RouterModule,
        NgSelectModule,
        ReactiveFormsModule,
    ],
    entryComponents: [],
})
export class SharedModule {
}
