import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {HelperService} from '../../shared/service/helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private activeUserDataSource = new BehaviorSubject<any>([]);
  activeUserData = this.activeUserDataSource.asObservable();


  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private jwtHelperService: JwtHelperService,
    private _hs: HelperService
  ) {
  }

  setActiveUser(data: any) {
    if (!this._hs.isEmpty(data)) {
      this.activeUserDataSource.next(data);
    } else {
      this.activeUserDataSource.next(localStorage.getItem('user'));
    }
  }

  getActiveUser() {
    this.httpClient.get(`auth/user`).subscribe(data =>{
      this.activeUserDataSource.next(data);
    })
    // if (localStorage.getItem('user')){
    //   return JSON.parse(localStorage.getItem('user'));
    // }
  }

  requestAccessToken(loginData: any): Observable<object> {
    return this.httpClient.post(`auth/login`, loginData);
  }

  requestRefreshToken(): any {
    // const http = new HttpClient(this.handler);
    // return http.post(`http://3.135.47.236/api/auth/token`, {
    //   refresh_token: this.getRefreshToken(localStorage.getItem('refreshToken')),
    // });
  }

  isLoggedIn(): boolean {
    return !!this.getAccessToken() && !!this.getRefreshToken(localStorage.getItem('refreshToken'));
  }

  getAccessToken(): any {
    return this.rememberMe()
      ? localStorage.getItem('Token')
      : sessionStorage.getItem('Token');
  }

  // getRefreshToken(): any {
  //   return this.rememberMe()
  //     ? localStorage.getItem('Token')
  //     : sessionStorage.getItem('Token');
  // }


  getRefreshToken(refreshToken: any): Observable<any> {
    // @ts-ignore
    const data = {
      refresh_token : refreshToken
    }
    // debugger
    return this.httpClient.post(`auth/token`, data);
  }

  setAccessToken(value: string, rememberMe = false): any {
    localStorage.setItem('rememberMe', rememberMe ? 'true' : 'false');
    if (rememberMe) {
      localStorage.setItem('Token', value);
    } else {
      sessionStorage.setItem('Token', value);
    }
    this.getUserInfo();
  }

  // tslint:disable-next-line:typedef
  setRefreshToken(value: string) {
    if (this.rememberMe()) {
      localStorage.setItem('refreshToken', value);
    } else {
      sessionStorage.setItem('refreshToken', value);
    }
  }

  getUserInfo(): any {
    const token = this.getAccessToken();
    if (token) {
      const decodedToken = this.jwtHelperService.decodeToken(token);
      if (decodedToken) {
        return {
          id: decodedToken.user_id,
          groups: [decodedToken.role]
        };
      }
    }
    return;
  }

  logout(): void {
    const data = {
      refresh_token : localStorage.getItem('refreshToken')
    }
    this.httpClient.post(`auth/logout`, data).subscribe(res => {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('Token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      this.router.navigateByUrl('login').then(
      );
    }, error => {
      console.log(error);
    });
  }

  registerUser(data, userId, token): Observable<any> {
    return this.httpClient.post(`auth/user-onboarding/${userId}/${token}`, data);
  }

  loginUser(data): Observable<any> {

    return this.httpClient.post(`auth/login/`, data);
  }

  uploadProfilePicture(data: any): Observable<any> {
    return this.httpClient.post(`users/profile-pic`, data);
  }

  removeProfilePicture(data: any): Observable<any> {
    const body = {
      user: data,
    };
    return this.httpClient.request('DELETE', `users/profile-pic`, {
      body: {user: data},
    });
  }

  // @ts-ignore
  sendPasswordResetEmail = (data): Observable<object> => {
    return this.httpClient.post(`users/forgot-password`, data)
  }

  updateUser(userData: any): Observable<object> {
    return this.httpClient.put(`auth`, userData);
  }

  updatePassword(password) {
    return this.httpClient.post(`auth/change-password`, password);
  }

  getUserDetail() {
    return this.httpClient.get(`auth/user`);
  }

  private rememberMe(): boolean {
    return localStorage.getItem('rememberMe') === 'true';
  }

  getUsers(queryParams) {
    return this.httpClient.get(`users?${queryParams}`);
  }

  getUserById(id) {
    return this.httpClient.get(`users/${id}`);
  }

  updateUserInfo(id,userData) {
    return this.httpClient.put(`users/${id}`, userData);
  }


  createNewUser(userData) {
    return this.httpClient.post(`users`, userData);
  }

  deleteUserProfile(id): Observable<object> {
    return this.httpClient.delete(`users/${id}`);
  }

  verifyPasswordResetConfirmationToken(queryParams){
    return this.httpClient.get(`users/verify-reset-token?${queryParams}`);
  }

  resetPasswordConfirmation(data){
    return this.httpClient.post(`users/reset-password`, data);
  }
}
