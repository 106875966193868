import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardService } from '../core/services/dashboard.service';
import { DashboardData, DashboardDetails } from '../core/models/dashboard';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    dashboardData: DashboardDetails;

    constructor(
        private router: Router,
        private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.getDashboardData();
    }

    getDashboardData() {
        this.subscriptions.push(this.dashboardService.getDashboardData()
            .subscribe((data: DashboardData) => {
                this.dashboardData = data?.data;
            }, error => {
            }))
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(x => x.unsubscribe());
        }
    }

    redirectToTheirPage(pageType:string): void{
        switch (pageType){
            case 'tags':
                this.router.navigate(['/admin/tags']);
                break;
            case 'users':
                this.router.navigate(['/admin/users']);
                break;
            default:
                this.router.navigate(['/admin/on-demand-video']);
                break;
        }
    }
}
