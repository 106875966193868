import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation} from '@angular/core';
import {CdkTable} from '@angular/cdk/table';
import { DEFAULT_SETTING } from '../../../core/models/defaults';

@Component({
  selector: 'app-table, table[app-table]',
  exportAs: 'appTable',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TableComponent<T> extends CdkTable<T> implements OnDestroy {
  protected stickyCssClass = 'table--header-sticky';
  @Input() height: string;
  @Input() border = false;
  @Input() noDataMessage: string;
  @Input() pageSize = 10;
  @Input() dataCount = 0;
  @Input() shouldShowPagination = false;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() shouldShowAddButton = false;
  @Input() buttonName: string;
  @Input() buttonRouteLink = '/';

  @Output() pageNumberClicked: EventEmitter<any> = new EventEmitter<any>();
  page = 1;
  maxPaginationSize = DEFAULT_SETTING.MAX_PAGINATION_LINK_COUNT;

  pageChanged = (pageNumber) => {
    this.page = pageNumber;
    this.pageNumberClicked.emit(pageNumber);
  };
  ngOnDestroy(): void {
  }
}
