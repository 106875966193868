import { Injectable } from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        // @ts-ignore
        this.validateAllFormFields(control);
      }
    });
  }

  isEmpty(subject: any) {
    return (!subject || subject.length === 0 );
  }

  isBlank(str: any) {
    return (!str || /^\s*$/.test(str));
  }

}
