
// Expects a date something like: 2020-12-24
// Return { year: 2020, month: 12, day: 26 }
import { FormGroup, ValidationErrors } from '@angular/forms';
import { environment } from '../../../environments/environment';

export const getFormattedDate = (dateString: string): object => {
  if (!dateString) {
    return {};
  }
  const splittedDate = dateString.split('-');

  return {
    year: parseInt(splittedDate[0], 10),
    month: parseInt(splittedDate[1], 10),
    day: parseInt(splittedDate[2], 10),
  };
};

// Expects something like: {year: 2020, month: 12, day: 26}
// Returns 2020-11-12
export const getDateString = (dateString: { year, month, day }, separator = '-'): string => {
  if (dateString && dateString.hasOwnProperty('year')) {
    return `${dateString.year}${separator}${dateString.month}${separator}${dateString.day}`;
  }

  return null;
};

export const hasStringWhiteSpace = (testString) => /\s/.test(testString);



export const getFormattedDateClassObject: object = (dateObject: { year, month, day }) =>
  new Date(dateObject.year, dateObject.month - 1, dateObject.day);

// filterConditions is a key value pair or query params
export const constructQueryParams = (filterConditions: {}): string => {
  const url = Object.keys(filterConditions)
    .map(queryKey => {
        return queryKey + '=' + filterConditions[queryKey];
    })
    .join('&');

  return url.replace('&&', '&');
};

export function truncateString(
  toTruncateString,
  maxLength = 10,
  shouldDynamicallyDecreaseContent = false
): string {
  if (toTruncateString && toTruncateString.length > maxLength) {
    const computedLength = (shouldDynamicallyDecreaseContent && screen.width < 450) ? 20 : maxLength;
    return `${toTruncateString.slice(0, computedLength)}...`;
  }
  return toTruncateString;
}

export const isEnvProduction = () => !
  (
    window.location.href.includes('localhost') ||
    window.location.href.includes('127.0.0.1') ||
    window.location.href.includes('http://3.22.5.111/')
  );


export const pushToArrayIfNotAlreadyExists = (arrayToBeChecked, newElement, shouldKeptFirst = false) => {
  if (!arrayToBeChecked.includes(newElement)) {
    if (shouldKeptFirst) {
      arrayToBeChecked.unshift(newElement);
    }
    arrayToBeChecked.push(newElement);
  }
};

export function capitalizeFirstLetter(argumentString): string {
  if (argumentString) {
    return argumentString.charAt(0).toUpperCase() + argumentString.slice(1);
  }
  return '';
}

export const isEnvLocal = (): boolean => window.location.href.includes('localhost');

export const printFormError = (form: FormGroup): void => {
  if (!environment.production){
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
}

export function getHumanReadableName(str: string): string{
  if(!str){
    return '';
  }
  const arr = str.replace('_',' ').split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
}
