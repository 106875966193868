import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tags-with-border',
  templateUrl: './tags-with-border.component.html',
  styleUrls: ['./tags-with-border.component.scss']
})
export class TagsWithBorderComponent implements OnInit {
  @Input() text = '-';

  constructor() { }

  ngOnInit(): void {
  }

}
