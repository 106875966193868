import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import videojs, { VideoJsPlayerOptions } from 'video.js';

declare function registerIVSTech(videojs: unknown, options: { wasmWorker: string; wasmBinary: string; }): unknown;
declare function registerIVSQualityPlugin(videojs: unknown): unknown;
declare module 'video.js' { interface VideoJsPlayer { enableIVSQualityPlugin(): unknown; } }

@Component({
  selector: 'app-vjs-player',
  templateUrl: './vjs-player.component.html',
  styleUrls: ['./vjs-player.component.scss']
})
export class VjsPlayerComponent implements OnInit {

  @ViewChild('target', {static: true})
  target!: ElementRef;
  
  @Input()
  videoUrl: string = "";

  private player!: videojs.Player;

  constructor() {}

  // Instantiate a Video.js player OnInit
  ngOnInit() {
    const techOptions = {
      wasmWorker: this.createAbsolutePath('/assets/amazon-ivs/amazon-ivs-wasmworker.min.js'),
      wasmBinary: this.createAbsolutePath('/assets/amazon-ivs/amazon-ivs-wasmworker.min.wasm')
    }
    
    registerIVSTech(videojs, techOptions);
    registerIVSQualityPlugin(videojs);

    const options: VideoJsPlayerOptions = {
      bigPlayButton: true,
      liveui: true,
      playbackRates: [0.5, 1, 1.5, 2],
      fluid: true,
      responsive: true,
      aspectRatio: "16:9",
      techOrder: ["AmazonIVS"]
    };

    this.player = videojs(this.target.nativeElement, options);
    this.player.src(this.videoUrl);
    this.player.play();
    this.player.enableIVSQualityPlugin();
  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  private createAbsolutePath(assetPath: string) {
    return new URL(assetPath, document.URL).toString();
  }

}
