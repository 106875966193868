import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  AfterViewInit,
  Injector
} from '@angular/core';
import {
  NgbTimeStruct,
  NgbDateStruct,
  NgbPopoverConfig,
  NgbPopover,
  NgbDatepicker, NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NgControl, ControlContainer, AbstractControl, FormControl
} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {noop} from 'rxjs';
import {DateTimeModel} from '../../models/datetime.model';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent
  implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input()
  dateString: string;

  @Input()
  inputDatetimeFormat = 'M/d/yyyy H:mm:ss';
  @Input()
  hourStep = 1;
  @Input()
  minuteStep = 15;
  @Input()
  secondStep = 30;
  @Input()
  seconds = true;

  @Input()
  disabled = false;

  @Input() disablePastDate = false;
  showTimePickerToggle = false;

  dateTime: DateTimeModel = new DateTimeModel();
  firstTimeAssign = true;

  // @ViewChild(NgbDatepicker, { static: true })
  // private dp: NgbDatepicker;

  @ViewChild(NgbPopover, {static: true})
  popover: NgbPopover;

  onTouched: () => void = noop;
  onChange: (_: any) => void = noop;

  ngControl: NgControl;
  @Input() formControlName: string;
  @Input() formControl: FormControl;
  control: AbstractControl;
  defaultValidator: any;

  constructor(private config: NgbPopoverConfig,
              private inj: Injector,
              private datePickerConfig: NgbDatepickerConfig,
              private controlContainer: ControlContainer
  ) {
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
    if(this.disablePastDate){
      const current = new Date();
      this.datePickerConfig.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
    }

    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get('' + this.formControlName);
        const validator = this.control.validator && this.control.validator({} as AbstractControl);
        this.defaultValidator = validator || {};
      } else if (this.formControl) {
        this.control = this.formControl;
        const validator = this.control.validator && this.control.validator({} as AbstractControl);
        this.defaultValidator = validator || {};
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }
  }

  ngAfterViewInit(): void {
    this.popover.hidden.subscribe($event => {
      this.showTimePickerToggle = false;
    });
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.dateTime = Object.assign(
        this.dateTime,
        DateTimeModel.fromLocalString(newModel)
      );
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.dateTime = new DateTimeModel();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDateTimeState($event) {
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    const dt = DateTimeModel.fromLocalString(value);

    if (dt) {
      this.dateTime = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.dateTime = new DateTimeModel();
      this.dateString = '';
      this.onChange(this.dateString);
    } else {
      this.onChange(value);
    }
  }

  onDateChange($event: string | NgbDateStruct, dp: NgbDatepicker) {
    const date = new DateTimeModel($event);

    if (!date) {
      this.dateString = this.dateString;
      return;
    }

    if (!this.dateTime) {
      this.dateTime = date;
    }

    this.dateTime.year = date.year;
    this.dateTime.month = date.month;
    this.dateTime.day = date.day;

    const adjustedDate = new Date(this.dateTime.toString());
    if (this.dateTime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
      this.dateTime.timeZoneOffset = adjustedDate.getTimezoneOffset();
    }

    this.setDateStringModel();
  }

  onTimeChange(event: NgbTimeStruct) {
    this.dateTime.hour = event.hour;
    this.dateTime.minute = event.minute;
    this.dateTime.second = event.second;

    this.setDateStringModel();
  }

  setDateStringModel() {
    this.dateString = this.dateTime.toString();

    if (!this.firstTimeAssign) {
      this.onChange(this.dateString);
    } else {
      // Skip very first assignment to null done by Angular
      if (this.dateString !== null) {
        this.firstTimeAssign = false;
        this.onChange(this.dateString);
      }
    }
  }

  inputBlur($event) {
    this.onTouched();
  }
}
