import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-title-slider',
  templateUrl: './icon-title-slider.component.html',
  styleUrls: ['./icon-title-slider.component.scss']
})
export class IconTitleSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
