export enum DEFAULT_SETTING{
    MAX_PAGE_SIZE= 10,
    MAX_PAGINATION_LINK_COUNT= 4,
    DEBOUNCE = 1000,
    VIMEO_LINK = 'https://vimeo.com/manage/videos'
}

export interface Pagination{
    current_page: number,
    from: number,
    last_page: number,
    per_page: number,
    to: number,
    total: number
}
