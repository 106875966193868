import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OnDemandMediaType } from '../../../core/models/series.model';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit, OnChanges {
  @Input() mediaUrl : string;
  @Input() type = null;
  isVimeoURL = false;
  @Output() removeImageEventEmitter: EventEmitter<any> = new EventEmitter();
  constructor() { }
  ngOnInit(): void {
  }

  removeImageEvent(){
    this.removeImageEventEmitter.emit(this.type);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.mediaUrl){
      this.isVimeoURL =  this.mediaUrl.includes('vimeo');
    }
  }
}
