import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd} from '@angular/router';
import {AuthService} from './core/services/auth.service';
import {HelperService} from './shared/service/helper.service';
import { LoaderService } from './shared/service/loader.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;

  constructor(private router: Router,
              private auth: AuthService,
              private _hs: HelperService,
              private loaderService:LoaderService

  ) {
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    router.events.forEach((event: any) => {
      if (event instanceof NavigationStart) {
        const currentUrl = event['url'];
        const isAdminUrl = currentUrl.includes('/admin/');
        if (!isAdminUrl) {
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
          document.querySelector('.main-panel').classList.add('w-100');
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg',);
          document.querySelector('.content-wrapper').classList.remove('auth', 'lock-full-bg');
          if ((event['url'] === '/error-pages/404') || (event['url'] === '/error-pages/500')) {
            document.querySelector('.content-wrapper').classList.add('p-0');
          }
        } else {
          this.showSidebar = true;
          this.showNavbar = true;
          this.showFooter = true;
          document.querySelector('.main-panel').classList.remove('w-100');
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg');
          document.querySelector('.content-wrapper').classList.remove('p-0');
        }
      }
    });

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loaderService.isLoading.next(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loaderService.isLoading.next(false);
      }
    });
  }


  ngOnInit() {
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
