export const environment = {
  production: false,
  HOST: 'test-admin.xfinityfrontrow.com',
  API_HOST: '/test-admin.xfinityfrontrow.com/api/',
  PROTOCOL: 'https:',
  S3_ACCESS_KEY_ID: 'AKIAZCY2DY4NOGFHM4FK',
  S3_SECRET_ACCESS_KEY: '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',
  S3_REGION: 'us-east-2',
  S3_BUCKET: 'test-xfinity-bucket',
};
