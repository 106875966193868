import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../core/services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;
  activeUser: any;
  closeResult = '';
  tagsActive = false;
  @ViewChild('tags', {static: false}) private tags;

  constructor(private route: ActivatedRoute, private authService: AuthService, private modalService: NgbModal,) {
  }

  ngOnInit() {
    this.activateSidebar();
    this.authService.activeUserData.subscribe(data => {
      this.activeUser = data.data
    });
  }


  activateSidebar() {
    const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  openTags() {
    this.tagsActive = true;
    this.modalService.open(
      this.tags,
      {ariaLabelledBy: 'modal-basic-title', windowClass: 'series-create-form'}
    ).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      // this.tagsActive = false;
    }, (reason) => {
      this.closeResult = `Dismissed`;
      // this.tagsActive = false;
    });
  }

}

