import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'admin',
    resolve: {},
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'admin/dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {
        path: 'tags',
        loadChildren: () => import('./modules/tags/tags.module').then(u => u.TagsModule)},
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(u => u.UsersModule)
      },
      {
        path: '',
        loadChildren: () => import('./modules/on-demand-video/on-demand-video.module').then(m => m.OnDemandVideoModule)
      },
    ]
  },
  { path: '', loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule) },
  { path: 'error', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule)},
  { path: '**', redirectTo: 'error', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
