import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from '../services/auth.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {catchError, switchMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {HelperService} from '../../shared/service/helper.service';
import { getHumanReadableName } from '../services/utilities';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  originalRequest = '';

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  token: any;

  constructor(
    private authService: AuthService,
    private jwtHelperService: JwtHelperService,
    private injector: Injector,
    private router: Router,
    private toastr: ToastrService,
    private _hs: HelperService,
  ) {}
  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress === true) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      if (localStorage.getItem('refreshToken')){
        this.authService.getRefreshToken(localStorage.getItem('refreshToken')).subscribe((response) => {
          localStorage.setItem('Token', response?.data.token);
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();

          // return next.handle(this.originalRequest)
        }, (err: any) => {
          this.refreshTokenInProgress = false;
          this.authService.logout();
          // return Observable.throw(err.statusText);
        });
      }
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    }
  }

  // @ts-ignore
  handleResponseError(error, request?, next?) {
    const count = 0 ;
    this.originalRequest = error?.url;
    // Business error
    if (error.status === 400) {
      if (this.refreshTokenInProgress) {
        this.authService.logout();
      }
      this.interceptResponseAndHandleException(error);
    }

    // Invalid token error
    else if (error.status === 401) {
      // this.toastr.error('Invalid data or token',  'Invalid');
      // this.refreshTokenInProgress = true;
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            // return next.handle(request);
            return this.handleResponseError(e);
          } else {
            this.authService.logout();
          }
        }));
    }

    // Access denied error
    else if (error.status === 404) {
      // authService.logout
      this.toastr.error('Access Denied');
      // this.authService.logout();
    }

    // Access denied error
    else if (error.status === 403) {
      this.toastr.error('user unauthorized');
      // authService.logout
      // this.authService.logout();
    }

    // Server error
    else if (error.status === 500) {
      this.toastr.error('internal server error, please contact administration for support!')
    }

    // Maintenance error
    else if (error.status === 503) {
      this.toastr.error('internal server error, please contact administration for support!')
      // Show message
      // Redirect to the maintenance page
    }else{
      this.interceptResponseAndHandleException(error);
    }
    return throwError(error);
  }

  addAuthHeader(request: HttpRequest<any>) {
    const authHeader = localStorage.getItem('Token');
    let headers = {
      Accept: `*/*`,
      Authorization: undefined,
    }
    if (authHeader && request.url !== 'auth/token') {
       headers = {
        Authorization: `Bearer ${authHeader}`,
        Accept: `*/*`,
       }
    }else{
      delete headers.Authorization;
    }
    let url = request.url;
    if(!url.includes('http')){
      url = environment.PROTOCOL + '//' + environment.API_HOST + request.url
    }
    return request.clone({
      url,
      headers: new HttpHeaders(headers)
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.authService = this.injector.get(AuthService);
    request = this.addAuthHeader(request);
    return next.handle(request).pipe(catchError(error => {
      return this.handleResponseError(error, request, next);
    }));
  }

  interceptResponseAndHandleException(error) {
    if (error.error !== undefined) {
      const err = error?.error.error;
      if (err.details){
        err.details.forEach(data =>{
          this.toastr.error(data.message,  getHumanReadableName(data.param));
        })
      } else if (err?.message){
        if (Array.isArray(err?.message)) {
          err?.message.forEach(data =>{
            this.toastr.error(data,  getHumanReadableName(data.param));
          })
        } else {
          this.toastr.error(err.message)
        }
      }else{
        this.toastr.error(err);
      }
    } else {
      for (const key in error) {
        if (error.hasOwnProperty(key)) {
          this.toastr.error(error[key]);
        }
      }
    }
  }
}

