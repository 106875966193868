import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
// import Player from '@vimeo/player';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss']
})
export class VimeoPlayerComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('player_container') playerContainer: any;
  @Input('videoUrl') public videoUrl: any = '';
  public  sanitizedUrl: any = null;

  constructor(private domSanitizer: DomSanitizer, private cdk: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

  ngAfterViewInit(): void {
    this.cdk.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }
}
