import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-with-legend',
  templateUrl: './input-with-legend.component.html',
  styleUrls: ['./input-with-legend.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithLegendComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputWithLegendComponent),
      multi: true
    }
  ]
})
export class InputWithLegendComponent implements OnInit {

  attrType = 'text';
  @Input() maskData: any;
  @Input() theme: 'light' | 'dark' = 'light';

  @Input() formControlName: string | undefined;
  @Input() formControl: FormControl | undefined;

  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() align: 'left' | 'center' | 'right' = 'left';

  @Input() type: string | undefined;

  @Input() accept: string | undefined;
  @Input() min: number = 1;
  @Input() max: number = 100;
  @Input() fixedHeight: boolean | undefined;

  @Input() id = '';
  @Input() maxCharLength: any;
  @Input() label: string | undefined;
  @Input() rows: number | 5;

  private _inputValue: any;

  // @Input('inputValue') _inputValue = '';
  get inputValue(): any {
    return this._inputValue;
  }

  @Input()
  set inputValue(val) {
    this._inputValue = val;
    try {
      this.propagateChange(val);
    } catch (e) {
    }
    this.stopValidation();
    if (this.attrType === 'file' && (val === '' || !val)) {
      this.fileLabel = '';
    }
  }

  @Input() placeholder = '';


  @Input() disabled = false;

  @Input() disableValidation = false;

  @Input() autocomplete = true;

  @Input() readonly = false;

  @Input() inputGroupPrepend = null;

  @Input() inputGroupAppend = null;

  @Output() change: EventEmitter<any> = new EventEmitter();

  control: AbstractControl | any;

  fileLabel = '';
  defaultValidator: any;

  @Input() shouldValidate = true;
  @Input() labelSize: 'sm' | 'md' | 'lg' = 'md';
  @Input() bgColor: 'grey' | 'white' = 'grey';


  constructor(
    private controlContainer: ControlContainer,
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
    if (this.controlContainer) {
      if (this.formControlName) {
        // @ts-ignore
        this.control = this.controlContainer.control.get('' + this.formControlName);
        // @ts-ignore
        const validator = this.control.validator && this.control.validator({} as AbstractControl);
        this.defaultValidator = validator || {};
      } else if (this.formControl) {
        this.control = this.formControl;
        const validator = this.control.validator && this.control.validator({} as AbstractControl);
        this.defaultValidator = validator || {};
      } else {
        console.warn('Missing FormControlName directive from host element of the component');
      }
    } else {
      console.warn('Can\'t find parent FormGroup directive');
    }
  }

  onChange(event: any): void {
    this.change.emit(event);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.inputValue = value;
    }
  }

  propagateChange = (_: any) => {
  };

  onTouchedCallback = () => {
  };

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  beginValidation(): void {
    this.shouldValidate = !(this.inputValue === '' && !('required' in this.defaultValidator));
  }

  // Set touched on blur
  onBlur(): void {
    this.beginValidation();
    this.onTouchedCallback();
  }

  stopValidation(): void {
    this.shouldValidate = false;
  }


  validate(c: AbstractControl): any {
    if (this.disableValidation) {
      return null;
    }

    switch (this.type) {
      case 'name': {
        if (c.value && c.value.length >= 100) {
          return {
            fieldError: ['Name is too long.']
          };
        }
        break;
      }
      case 'phone': {
        if (!this.defaultValidator.required) {
          return null;
        }
        if (!/^(\+)?(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/.test(c.value)) {
          return {
            fieldError: ['Enter a valid phone number.']
          };
        }
        break;
      }
      case 'email': {
        if (!this.defaultValidator.required) {
          return null;
        }
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(c.value)) {
          return {
            fieldError: ['Enter a valid email.']
          };
        }
        break;
      }
    }
  }

  focus(): void {
    let input = this.el.nativeElement.getElementsByTagName('input');
    if (input.length < 1) {
      input = this.el.nativeElement.getElementsByTagName('textarea');
    }

    if (input.length > 0) {
      input[0].focus();
    }
  }

  hasRequiredField = (abstractControl: AbstractControl): boolean => {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  };

  keyUpPerformed = (inputChangeEvent: any) => {
    if (this.type === 'number') {
      const numberValue = inputChangeEvent.target.value;
      if (numberValue.split('.') && numberValue.split('.')[1] && numberValue.split('.')[1].length > 2) {
        this.inputValue = parseFloat(numberValue).toFixed(2);
      }
    }
  };
}
